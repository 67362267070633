import {
  Typography,
  Box,
  Button,
  Tooltip,
  LinearProgress,
  Stack,
  Checkbox,
  CircularProgress,
  IconButton,
  Popover,
  Avatar
} from '@mui/material';
import { useMemo, useState } from 'react';
import './styles/styles.css';
import DataGrid, { Column } from 'react-data-grid';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import ConvertToCurrencySystem from '../../../Shared/ConvertToCurrency';
import Pagination from './Pagination';
import { IBillFiles, IParams } from '../../interface';
import { EyeOutline, Filter } from 'mdi-material-ui';
import SearchFilter from '../../../../components/TableFilters/SearchFilter';
import CustomAutoComplete from '../../../../components/CustomAutocomplete';
import CheckboxFilter from '../../../../components/TableFilters/CheckboxFilter';
import AgentAssignAutocomplete from '../../../../components/AgentAssignAutocomplete';
import { IAgent } from '../../../TaskManagementSystem/interface';
import BillsUploadDialog from '../../Bills/BillsUploadDialog';

interface IPOTable {
  posList: any[];
  isFetching: boolean;
  isSendingMail: boolean;
  rowsPerPage: number;
  params: IParams;
  onPageChange: (page: number) => void;
  sendPOReminders: (poIds: string[]) => void;
  onFilterPoNumber: (poNumber: string) => void;
  onFilterCampaign: ({ _id, name }: { _id: string; name: string }) => void;
  onFilterHasBills: (value: boolean) => void;
  onFilterBrand: (brand: string) => void;
  onFilterVendor: (vendor: string) => void;
  onFilterCreatedBy: (agent: IAgent) => void;
}

const POTable = ({
  posList,
  isFetching,
  isSendingMail,
  rowsPerPage,
  params,
  onPageChange,
  sendPOReminders,
  onFilterPoNumber,
  onFilterCampaign,
  onFilterHasBills,
  onFilterBrand,
  onFilterVendor,
  onFilterCreatedBy
}: IPOTable) => {
  const navigate = useNavigate();
  const [selectedPoIds, setSelectedPoIds] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [FilterComponent, setFilterComponent] =
    useState<React.ReactNode | null>();
  const [open, setOpen] = useState<IBillFiles[] | null>(null);
  const [poId, setPoId] = useState<string>('');

  const stringAvatar = (assignedTo: IAgent) => {
    const { name = '', _ppic = null } = assignedTo || {};
    const splitName = name.split(' ');
    return {
      children: splitName[0] ? splitName[0][0] : ''
    };
  };

  const columns: Column<any>[] = useMemo(
    (): Column<any>[] => [
      {
        key: 'selection',
        name: '',
        renderHeaderCell: (data) => (
          <Checkbox
            disableFocusRipple
            checked={selectedPoIds.length === rowsPerPage}
            indeterminate={selectedPoIds.length > 0}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.target.checked
                ? setSelectedPoIds(
                    posList.map((po: any) => po?.order?.purchaseorder_id)
                  )
                : setSelectedPoIds([]);
            }}
          />
        ),
        renderCell: ({ row }) => (
          <Checkbox
            disableFocusRipple
            checked={selectedPoIds.includes(row?.order?.purchaseorder_id)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              e.target.checked
                ? setSelectedPoIds((prevState) => [
                    ...prevState,
                    row?.order?.purchaseorder_id
                  ])
                : setSelectedPoIds(
                    selectedPoIds.filter(
                      (id: string) => id !== row?.order?.purchaseorder_id
                    )
                  )
            }
          />
        )
      },
      {
        key: 'date',
        name: 'Date',
        renderCell: ({ row }) => format(new Date(row?.order?.date), 'P')
      },
      {
        key: 'poNumber',
        name: 'PO#',
        renderHeaderCell: (data) => (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <Typography variant="h5" fontWeight={600}>
              PO#
            </Typography>
            <IconButton
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setFilterComponent(
                  <SearchFilter
                    onInputChange={(poNumber: string) => {
                      onFilterPoNumber(poNumber);
                    }}
                    defaultValue={params?.filters?.poNumber || ''}
                  />
                );
              }}
              sx={{ padding: '0px !important' }}
            >
              <Filter sx={{ height: '18px', width: '18px' }} />
            </IconButton>
          </Stack>
        ),
        renderCell: ({ row }) => (
          <Button
            variant="text"
            sx={{
              fontSize: '14px',
              color: '#5E9DFC',
              fontWeight: 500
            }}
            onClick={() =>
              navigate(`/purchase-orders/${row?.order?.purchaseorder_id}`)
            }
          >
            {row?.order?.purchaseorder_number}
          </Button>
        )
      },
      {
        key: 'campaign',
        name: 'Campaign',
        renderHeaderCell: (data) => (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <Typography variant="h5" fontWeight={600}>
              Campaign
            </Typography>
            <IconButton
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setFilterComponent(
                  <CustomAutoComplete
                    onSelect={(campaign: any) => {
                      onFilterCampaign({
                        _id: campaign?._id || '',
                        name: campaign?.name || ''
                      });
                    }}
                    defaultValue={params?.filters?.campaign}
                    apiPath={'/admin/campaigns/list'}
                    showProperty="name"
                    urlProperty="name"
                    dataProperty="campaigns"
                  />
                );
              }}
              sx={{ padding: '0px !important' }}
            >
              <Filter sx={{ height: '18px', width: '18px' }} />
            </IconButton>
          </Stack>
        ),
        renderCell: ({ row }) => (
          <Button
            variant="text"
            sx={{
              fontSize: '14px',
              color: '#5E9DFC',
              fontWeight: 500
            }}
            onClick={() => navigate(`/campaigns/${row?._campaign?._id}`)}
          >
            {row?._campaign?.name || 'N/A'}
          </Button>
        )
      },
      {
        key: 'campaign_payable',
        name: 'Campaign Payable',
        renderCell: ({ row }) => (
          <Tooltip title={row?._campaign?.payable}>
            <Typography variant="h5">
              {row?._campaign ? (
                <ConvertToCurrencySystem
                  value={row?._campaign?.payable}
                  currency={true}
                />
              ) : (
                'N/A'
              )}{' '}
            </Typography>
          </Tooltip>
        )
      },
      {
        key: 'campaign_tax_payable',
        name: 'Campaign Total Payable(inc GST)',
        renderCell: ({ row }) => (
          <Tooltip title={row?._campaign?.totalPayable}>
            <Typography variant="h5">
              {row?._campaign ? (
                <ConvertToCurrencySystem
                  value={row?._campaign?.totalPayable}
                  currency={true}
                />
              ) : (
                'N/A'
              )}
            </Typography>
          </Tooltip>
        )
      },
      {
        key: 'vertical',
        name: 'Vertival',
        renderCell: ({ row }) => {
          const tools: string[] = [];
          row?.order?.line_items?.forEach((item: any) => {
            if (!tools.includes(item?.account_name))
              tools.push(item?.account_name);
          });
          return (
            <Typography variant="h5">
              {tools.length ? tools.join(' , ') : 'N/A'}
            </Typography>
          );
        }
      },
      {
        key: 'reference',
        name: 'Brand',
        renderHeaderCell: (data) => (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <Typography variant="h5" fontWeight={600}>
              Brand
            </Typography>
            <IconButton
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setFilterComponent(
                  <SearchFilter
                    onInputChange={(brand: string) => {
                      onFilterBrand(brand);
                    }}
                    defaultValue={params?.filters?.brandName || ''}
                  />
                );
              }}
              sx={{ padding: '0px !important' }}
            >
              <Filter sx={{ height: '18px', width: '18px' }} />
            </IconButton>
          </Stack>
        ),
        renderCell: ({ row }) => row?.order?.reference_number
      },
      {
        key: 'vendor',
        name: 'Vendor',
        renderHeaderCell: (data) => (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <Typography variant="h5" fontWeight={600}>
              Vendor
            </Typography>
            <IconButton
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setFilterComponent(
                  <SearchFilter
                    onInputChange={(vendor: string) => {
                      onFilterVendor(vendor);
                    }}
                    defaultValue={params?.filters?.vendorName || ''}
                  />
                );
              }}
              sx={{ padding: '0px !important' }}
            >
              <Filter sx={{ height: '18px', width: '18px' }} />
            </IconButton>
          </Stack>
        ),
        renderCell: ({ row }) => row?.order?.vendor_name
      },
      {
        key: 'createdBy',
        name: 'Created By',
        renderHeaderCell: (data) => (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <Typography variant="h5" fontWeight={600}>
              Created By
            </Typography>
            <IconButton
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setFilterComponent(
                  <AgentAssignAutocomplete
                    selectedAgent={
                      params?.filters &&
                      params?.filters?._user &&
                      params?.filters?._user[0]
                    }
                    onChange={onFilterCreatedBy}
                    type="internal"
                  />
                );
              }}
              sx={{ padding: '0px !important' }}
            >
              <Filter sx={{ height: '18px', width: '18px' }} />
            </IconButton>
          </Stack>
        ),
        renderCell: ({ row }) => (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={2}
          >
            {row?._user && (
              <Avatar
                {...stringAvatar(row?._user)}
                src={row?._user?._ppic?.url}
                sx={{
                  width: '22.15px',
                  height: '22.15px',
                  backgroundColor: 'primary.main'
                }}
              />
            )}
            {row?._user?.name || ''}
          </Stack>
        )
      },
      {
        key: 'status',
        name: 'Status',
        renderCell: ({ row }) => (
          <Typography
            sx={{
              fontSize: '14px',
              color: '#5E9DFC'
            }}
            fontWeight={500}
          >
            {row?.order?.status.toUpperCase()}
          </Typography>
        )
      },
      {
        key: 'billedStatus',
        name: 'Billed Status',
        renderCell: ({ row }) => (
          <Typography
            sx={{
              fontSize: '14px',
              width: 'fit-content',
              color:
                row?.billed_status === 'partially_billed'
                  ? '#E1BC44'
                  : '#5E9DFC'
            }}
            fontWeight={500}
          >
            {row?.order?.billed_status?.toUpperCase()}
          </Typography>
        )
      },
      {
        key: 'bills',
        name: 'Bills',
        renderHeaderCell: (data) => (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <Typography variant="h5" fontWeight={600}>
              Bills
            </Typography>
            <IconButton
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setFilterComponent(
                  <CheckboxFilter
                    message="Show POs with bills"
                    defaultValue={params?.filters?.hasBill || false}
                    onChange={onFilterHasBills}
                  />
                );
              }}
              sx={{ padding: '0px !important' }}
            >
              <Filter sx={{ height: '18px', width: '18px' }} />
            </IconButton>
          </Stack>
        ),
        renderCell: ({ row }) => (
          <>
            {row?.bills?.length ? (
              <Button
                onClick={() => {
                  setPoId(row?.order?.purchaseorder_id);
                  setOpen(row?.bills);
                }}
                variant="text"
                sx={{
                  fontWeight: 500,
                  padding: '4px 10px',
                  fontSize: '14px',
                  borderRadius: '4px'
                }}
                endIcon={<EyeOutline color="primary" />}
              >
                {`${row?.bills.length} Bills`}
              </Button>
            ) : (
              <Button
                onClick={() => {
                  setPoId(row?.order?.purchaseorder_id);
                  setOpen(row?.bills);
                }}
                variant="contained"
                sx={{
                  fontWeight: 500,
                  padding: '4px 10px',
                  fontSize: '14px',
                  borderRadius: '4px'
                }}
              >
                Upload Bills
              </Button>
            )}
          </>
        )
      },
      {
        key: 'billedValue',
        name: 'Billed Value',
        renderCell: ({ row }) => (
          <Tooltip title={row?.billedValue}>
            <Typography variant="h5">
              {row?.billedValue ? (
                <ConvertToCurrencySystem
                  value={row?.billedValue}
                  currency={true}
                />
              ) : (
                '-'
              )}
            </Typography>
          </Tooltip>
        )
      },
      {
        key: 'amount',
        name: 'Amount',
        renderCell: ({ row }) => (
          <Tooltip title={row?.order?.total}>
            <Typography variant="h5">
              <ConvertToCurrencySystem
                value={row?.order?.total}
                currency={true}
              />
            </Typography>
          </Tooltip>
        )
      },
      {
        key: 'expectedDeliveryDate',
        name: 'Expected Delivery Date',
        renderCell: ({ row }) => (
          <Typography sx={{ fontSize: '14px' }}>
            {format(
              new Date(
                row?.order?.expected_delivery_date || row?.order?.delivery_date
              ),
              'P'
            )}
          </Typography>
        )
      }
    ],
    [selectedPoIds, params, posList]
  );
  return (
    <>
      {selectedPoIds.length > 0 ? (
        <Stack
          sx={{ backgroundColor: '#f5f4fc', padding: '12px 34px 12px 20px' }}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography>{selectedPoIds.length} Item selected</Typography>
          <Button
            variant="outlined"
            sx={{
              border: '2px solid #4E3BC9',
              padding: '6px 16px',
              fontWeight: 500,
              '&:hover': { border: '2px solid #4E3BC9' }
            }}
            onClick={() => sendPOReminders(selectedPoIds)}
            disabled={isSendingMail}
            endIcon={isSendingMail && <CircularProgress size={18} />}
          >
            Send PO Reminder
          </Button>
        </Stack>
      ) : (
        <Box sx={{ height: '60px' }}></Box>
      )}

      <Stack
        position="relative"
        sx={{
          mb: '28px',
          maxHeight: 'calc(78vh - 52px)',
          gridColumnStart: 1,
          gridColumnEnd: -1,
          overflow: 'hidden'
        }}
      >
        {isFetching && (
          <LinearProgress
            sx={{ position: 'absolute', width: '100%', top: 0, zIndex: 1 }}
          />
        )}
        <DataGrid
          enableVirtualization={false}
          headerRowHeight={60}
          direction={'ltr'}
          columns={columns}
          rows={posList}
          style={{
            overflowX: 'auto',
            gridColumnStart: 1,
            gridColumnEnd: -1,
            height: '100%'
          }}
          rowHeight={60}
        />
        {anchorEl && (
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
          >
            {FilterComponent}
          </Popover>
        )}
        <Pagination
          params={params}
          dataSize={posList.length}
          rowsPerPage={rowsPerPage}
          onPageChange={(page: number) => onPageChange(page)}
        />
        {open && (
          <BillsUploadDialog
            open={Boolean(open)}
            onClose={() => setOpen(null)}
            bills={open}
            poId={poId}
          />
        )}
      </Stack>
    </>
  );
};

export default POTable;
