import { SingleFileUploadDragDrop, UploadDragAndDrop } from '@media-ant/ui';
import {
  Button,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { Close, DeleteOutline } from 'mdi-material-ui';
import { ChangeEvent, useState } from 'react';
import { IBillFiles, IFile } from '../interface';
import BillCard from './BillCard';
import { useAppDispatch } from '../../hooks';
import { billUpload } from '../redux/actionCreator';
import { useSelector } from 'react-redux';

interface IBillUploadDialogProps {
  open: boolean;
  onClose: () => void;
  bills: IBillFiles[];
  poId: string;
}

const BillsUploadDialog = ({
  open,
  onClose,
  bills,
  poId
}: IBillUploadDialogProps) => {
  const dispatch = useAppDispatch();
  const [files, setFiles] = useState<IFile[]>([]);
  const [isUploadingFiles, setIsUploadingFiles] = useState<boolean>(false);
  const [billingValue, setBillingValue] = useState('');
  const [poBills, setPOBills] = useState<IBillFiles[]>(bills);
  const { user } = useSelector((state: any) => state.session);
  const handleFileUpload = (value: any) => {
    setFiles((prevState) => {
      return [...prevState, value];
    });
  };

  const handleFileDelete = (idx: number) => {
    setFiles((prevState) => {
      let filteredState = [...prevState];
      filteredState.splice(idx, 1);
      return filteredState;
    });
  };

  const uploadBills = () => {
    setIsUploadingFiles(true);
    const formData = new FormData();
    files.forEach((file: any) => {
      formData.append('files', file);
    });
    formData.append('billValue', billingValue);
    dispatch(billUpload({ zohoPoId: poId || '', formData, user })).then(
      (res: any) => {
        if (!res.error) {
          setFiles([]);
          const { bills } = res.payload?.response;
          let tempBills = new Set(poBills.map((bill: IBillFiles) => bill._id));
          bills.forEach((bill: IBillFiles) => {
            if (!tempBills.has(bill._id)) {
              bill = { ...bill, _user: user };
              setPOBills((prevState: any) => [...prevState, bill]);
            }
          });
        }
        setIsUploadingFiles(false);
      }
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '750px',
          maxWidth: '750px',
          p: 4
        }
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography>Bills Upload</Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={4} p={2}>
        <Typography>Billing Amount : </Typography>
        <TextField
          size="small"
          type="number"
          value={billingValue}
          InputProps={{ inputProps: { min: 0 } }}
          placeholder="Enter the billing value"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setBillingValue(e.target.value)
          }
        />
      </Stack>
      <Divider sx={{ m: '10px' }} />
      <SingleFileUploadDragDrop
        maxFileSize="1.5 GB"
        onChange={handleFileUpload}
        isDisabled={Number(billingValue) === 0 || files.length === 1}
      />
      <Divider sx={{ m: '10px' }} />
      {files.length > 0 && (
        <>
          <Typography>Files</Typography>
          <Stack>
            {files?.map((file: IFile, index: number) => (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                p={'0px 10px'}
                key={index}
              >
                <Tooltip title={file.name}>
                  <Typography
                    noWrap
                    sx={{
                      maxWidth: '300px',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden'
                    }}
                  >
                    {file.name}
                  </Typography>
                </Tooltip>
                <IconButton
                  color="error"
                  onClick={() => handleFileDelete(index)}
                >
                  <DeleteOutline />
                </IconButton>
              </Stack>
            ))}
          </Stack>
          <Button
            variant="contained"
            onClick={() => uploadBills()}
            sx={{
              padding: '6px 22px',
              fontSize: '14px',
              borderRadius: '4px',
              fontWeight: 500
            }}
            disabled={isUploadingFiles}
            endIcon={isUploadingFiles && <CircularProgress size={18} />}
          >
            Submit
          </Button>
        </>
      )}
      {poBills.length > 0 && (
        <>
          <Divider sx={{ m: '10px' }} />
          <Typography>Bills</Typography>
          {poBills?.map((bill: IBillFiles, index: number) => (
            <BillCard bill={bill} poId={poId} key={index} />
          ))}
        </>
      )}
    </Dialog>
  );
};

export default BillsUploadDialog;
