import { CardMediaThumbnail } from '@media-ant/ui';
import {
  Avatar,
  Box,
  Button,
  Paper,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { IBillFiles } from '../interface';
import { IAgent } from '../../TaskManagementSystem/interface';
import { format } from 'date-fns';
import { CheckCircleOutline } from 'mdi-material-ui';
import { useNavigate } from 'react-router-dom';
import ConvertToCurrencySystem from '../../Shared/ConvertToCurrency';

interface IBillCardProps {
  bill: IBillFiles;
  poId: string;
}

const BillCard = ({ bill, poId }: IBillCardProps) => {
  const navigate = useNavigate();
  const isFinanceUser = true;

  const stringAvatar = (assignedTo: IAgent) => {
    const { name = '', _ppic = null } = assignedTo || {};
    const splitName = name.split(' ');
    return {
      children: (
        <Typography color="white">
          {splitName[0] ? splitName[0][0] : ''}
        </Typography>
      )
    };
  };

  return (
    <Paper elevation={1} sx={{ m: '10px 0px', p: '15px' }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={4}
        sx={{ width: '100%' }}
      >
        <Stack direction="row" spacing={2}>
          <CardMediaThumbnail
            url={bill?.url}
            style={{
              width: 80,
              height: 80,
              objectFit: 'contain'
            }}
          />
          <Stack spacing={1}>
            <a
              href={bill?.url}
              target="_blank"
              style={{
                textDecoration: 'none',
                width: '100%',
                overflow: 'hidden'
              }}
            >
              <Tooltip title={bill?.fileName}>
                <Typography
                  noWrap
                  variant="h4"
                  sx={{
                    maxWidth: '300px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    '&:hover': {
                      color: '#4E3BC9',
                      textDecoration: 'underline'
                    }
                  }}
                >
                  {bill?.fileName}
                </Typography>
              </Tooltip>
            </a>
            <Stack direction="row" alignItems="center" gap={2}>
              <Typography variant="subtitle2">Uploaded By :</Typography>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Avatar
                  {...stringAvatar(bill?._user)}
                  src={bill?._user?.name}
                  sx={{
                    width: '16px',
                    height: '16px',
                    backgroundColor: 'primary.main'
                  }}
                />
                <Typography variant="subtitle2">
                  {bill?._user?.name || ''}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Typography variant="subtitle2">Uploaded At :</Typography>
              <Typography variant="subtitle2">
                {format(new Date(bill?.createdAt), 'PPpp')}
              </Typography>
            </Stack>
            {bill?.billValue && (
              <Stack direction="row" alignItems="center">
                <Typography variant="subtitle2">Bill Value :</Typography>
                <Tooltip title={bill?.billValue}>
                  <Typography variant="subtitle2">
                    <ConvertToCurrencySystem
                      currency={true}
                      value={bill?.billValue}
                    />
                  </Typography>
                </Tooltip>
              </Stack>
            )}
          </Stack>
        </Stack>
        <Box>
          {bill?.approved ? (
            <Button
              variant="text"
              color="success"
              sx={{
                padding: '4px 12px',
                fontSize: '14px',
                borderRadius: '4px',
                fontWeight: 500,
                cursor: 'default'
              }}
              endIcon={<CheckCircleOutline color="success" />}
            >
              Approved
            </Button>
          ) : (
            <>
              {isFinanceUser ? (
                <Button
                  variant="contained"
                  sx={{
                    padding: '4px 12px',
                    fontSize: '14px',
                    borderRadius: '4px',
                    fontWeight: 500
                  }}
                  onClick={() =>
                    navigate(
                      `/purchase-orders/${poId}/bill/${
                        bill?._id
                      }?params=${encodeURIComponent(JSON.stringify(bill?.url))}`
                    )
                  }
                >
                  Convert to Bill
                </Button>
              ) : (
                <Typography sx={{ color: '#E1BC44' }} variant="subtitle2">
                  Waiting for finance approval
                </Typography>
              )}
            </>
          )}
        </Box>
      </Stack>
    </Paper>
  );
};

export default BillCard;
